<template>
	<div class="page">
		<Tables ref="xTable" :tableData="List" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showSeq="false" :showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.cfg_city" size="small" clearable placeholder="按城市查询">
					<el-option label="全部" value="全部"></el-option>
					<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.cfg_discount" size="small" clearable placeholder="按优惠业务查询">
					<el-option v-for="(item,k) in discountType" :key="k" :label="item" :value="k"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" size="small" clearable placeholder="按状态查询">
					<el-option label="开启" :value="1"></el-option>
					<el-option label="关闭" :value="0"></el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.city" title="城市" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.discount" title="优惠业务" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{discountType[row.cfg.discount]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.discountKind" title="优惠方式" align="center">
				<template v-slot="{ row }">
					<span>{{discountKind[row.cfg.discountKind]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.discountValue" title="立减/折扣" align="center">
				<template v-slot="{ row }">
					<span>{{row.cfg.discountValue + discountKindValue[row.cfg.discountKind]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.monthTime" title="循环次数" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{row.cfg.monthTime>0?"每月"+row.cfg.monthTime+"次":"首次"}}</span>
				</template>
			</vxe-table-column>			
			<vxe-table-column slot="table-item" field="status" title="状态" align="center">
				<template v-slot="{ row }">
					<span>{{row.status==1?'启用':'关闭'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="btime" title="有效时间" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{row.btime.substr(0,10)}} - {{row.etime.substr(0,10)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 编辑 -->
		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime" type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime" type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="归属城市" prop="city">
					<el-select v-model="editDialogFormData.cfg.city" placeholder="请选择" style="width:100%" @change="getGroups">
						<el-option label="全部" value="全部"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="优惠业务" prop="discount">
					<el-select v-model="editDialogFormData.cfg.discount" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in discountType" :key="k" :label="item" :value="k"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="editDialogFormData.cfg.discount == 0" label="参与电站" prop="group_ids">
					<el-select v-model="editDialogFormData.cfg.group_ids" placeholder="请选择场站" filterable multiple style="width:100%">
						<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="editDialogFormData.cfg.discount == 0" label="首次优惠方式" prop="discountFirstKind">
					<el-select v-model="editDialogFormData.cfg.discountFirstKind" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in discountKind" :key="k" :label="item" :value="k"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="editDialogFormData.cfg.discount == 0" label="首次优惠金额" prop="discountFirstValue">
					<el-input-number v-model="editDialogFormData.cfg.discountFirstValue" :min="0" :max="100" size="small"></el-input-number> {{ discountKindValue[editDialogFormData.cfg.discountFirstKind] }} <span style="color:green">0为首次无效</span>
				</el-form-item>
				<el-form-item label="优惠方式" prop="discountKind">
					<el-select v-model="editDialogFormData.cfg.discountKind" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in discountKind" :key="k" :label="item" :value="k"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="优惠金额" prop="discountValue">
					<el-input v-model="editDialogFormData.cfg.discountValue" size="small" style="width: 80%;"></el-input> {{ discountKindValue[editDialogFormData.cfg.discountKind] }}
				</el-form-item>
				<el-form-item label="每月可用次数" prop="monthTime">
					<el-input-number v-model="editDialogFormData.cfg.monthTime" :min="0" :max="100" size="small"></el-input-number>
				</el-form-item>
				<el-form-item label="启用状态" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script setup>
import Tables from '@/components/tables'
import {ref, onMounted} from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import Common from '@/utils/common.js'
import { MessageBox, Notification , Message} from 'element-ui'

const discountType = ["充电支付","购买会员","购买电卡","购买优惠券"];
const discountKind = ["立减","折扣"];
const discountKindValue = ["元","折"];
const isLoading = ref(false);
const totalPage = ref(0);
const searchFormData = ref({});
const List = ref([]);
const groupList = ref([]); //场站列表
const groupMap = ref({});
const selecttime = ref({});
let rules = {
	name: [{
		required: true,
		message: '请输入名称',
		trigger: 'change'
	}],
	city: [{
		required: true,
		message: '请选择区域',
		trigger: 'change',
		validator: (rule, value, callback)=>{
			if(!("city" in editDialogFormData.value.cfg)) return callback(new Error('请选择区域'));
			callback();
		}
	}],	
	group_ids: [{
		required: true,
		message: '请选择场站',
		trigger: 'change',
		validator: (rule, value, callback)=>{
			if(editDialogFormData.value.cfg.discount == 0 && (!("group_ids" in editDialogFormData.value.cfg) || editDialogFormData.value.cfg.group_ids.length < 1)) return callback(new Error('请选择场站'));
			callback();
		}
	}],
	btime: [{
		required: true,
		message: '请输入活动开始时间',
		trigger: 'change'
	}],
	etime: [{
		required: true,
		message: '请输入活动结束时间',
		trigger: 'change'
	}]
};

// 编辑
const editDialogShow = ref(false);
const editDialogFormData = ref({btime:"",etime:"",cfg:{group_ids:[]}});

onMounted(()=>{
	//获取电站列表
	getGroups();	
});
// 表格列表
async function getList(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		tp: 8,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, searchFormData.value);
	isLoading.value = true
	const res = await API.Market.ActivityCommon.list(params);
	for(let v of res.data){
		v.cfg = JSON.parse(v.cfg);
	} 
	List.value = res.data
	totalPage.value = res.total
	isLoading.value = false
}
function getGroups() { //获取电站列表
	const params = {
		token: store.state.user.token,
		page: 1,
		size: 100,
		city: editDialogFormData.value.cfg.city
	}
	API.Device.GetGroupMinList(params).then((res)=>{
		groupList.value = res;
		for(let v of groupList.value){
			groupMap.value[v.group_id] = v.group_name;
		}
		let gids = [];
		for(let gid of editDialogFormData.value.cfg.group_ids){
			for(let gv of groupList.value){
				if(gv.group_id == gid){
					gids.push(gid);
				}
			}
		}
		editDialogFormData.value.cfg.group_ids = gids;
	});
}
// 重置搜索
function clearSearch() {
	searchFormData.value = {}
}
const editForm = ref(null);
// 重置form
function resetFormData() {
	editDialogFormData.value = {btime:"",etime:"",status:1,cfg:{group_ids:[],discount:0,discountKind:0,discountFirstValue:0}}
	if (editForm.value) {
		editForm.value.resetFields()
	}
}
// 新增
function addOne() {
	resetFormData()
	editDialogShow.value = true
}
// 编辑
async function editOne(row) {
	resetFormData();
	//row.status = row.status.toString();
	editDialogFormData.value = Object.assign({}, row);
	delete editDialogFormData.value._XID;
	editDialogShow.value = true
}
const xTable = ref(null);
// 删除
function deleteOne(row) {
	MessageBox.confirm('确定要删除该记录吗', '提示', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(async () => {
		await API.Market.ActivityCommon.del({token: store.state.user.token, id: row.id})
		Notification({
			title: '成功',
			message: '删除成功',
			type: 'success'
		})
		xTable.value.refreshTable()
	})
}
// 关闭弹窗
function closeDialog() {
	editDialogShow.value = false
}
// 编辑保存
function saveEdit() {
	editForm.value.validate(async valid => {
		if (valid) {
			let params = {
				token: store.state.user.token,
				form: Object.assign({"tp":8}, this.editDialogFormData)
			}
			params.form.cfg.update_time = Common.DateTimeStr();
			params.form.cfg = JSON.stringify(params.form.cfg);
			params.form = JSON.stringify(params.form);
			if(editDialogFormData.value.id > 0){
				await API.Market.ActivityCommon.edit(params);
			}else{
				await API.Market.ActivityCommon.add(params);
			}
			Notification({
				title: '成功',
				message: '操作成功',
				type: 'success'
			})
			closeDialog()
			xTable.value.refreshTable()
		}
	})
}
</script>

<style lang="scss" scoped>
</style>
